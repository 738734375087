

.copertina {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
}

.copertina {
    transition: transform .3s ease, box-shadow .3s ease;
    cursor: pointer;
}

.copertina img {
    width: 100%;
    float: left;
}
.letto .copertina img {filter: grayscale(1);opacity: .5;}